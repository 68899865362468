<template>
  <view-wrapper :title="`询价单号：${form.data.serialNo}`">
    <template #header>
      <a-spin :spinning="form.loading">
        <a-form class="form-info">
          <a-row>
            <a-col :span="10">
              <a-form-item label="采购商：">
                <span>{{ form.data.tenantShortName }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item label="VIN码：">
                <span>{{ form.data.vin }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-item label="收货地址：">
                <span>{{ address }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item label="品牌/车型：">
                <span>{{ vehicleSpec }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-item label="询价人：">
                <span>{{ form.data.enocloudCommitUser.name }}</span>
                <a-tooltip :title="form.data.enocloudCommitUser.cellphone || '暂无'">
                  <PhoneFilled
                    class="cursor-pointer"
                    style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
                  />
                </a-tooltip>
                <MessageFilled
                  v-if="form.data.chatUser.name"
                  @click="webim.open(form.data.chatUser)"
                  class="cursor-pointer operation-model"
                  style="color: #ffd100; margin-left: 8px"
                />
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item label="开票要求：">
                <span>{{ form.data.invoiceType.message }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-item label="询价时间：">
                <span>{{ formatDate(form.data.commitDatetime) }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item label="备注：">
                <div class="ellipsis" style="width: 400px">{{ form.data.comment }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-item label="品质类型：">
                <span>{{ form.data.inquiryQualityTypes.map((item) => item.message).join(',') }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item label="附件：">
                <div class="w-full flex items-center">
                  <FileImageOutlined @click="form.preview.click" style="width: 11px; height: 14px; color: #0d8d8d" />
                  <span style="margin-left: 8px"
                    >({{ form.data.inquiryImages.length ? form.data.inquiryImages.length : 0 }}张)</span
                  >
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </template>
    <div style="padding: 0 24px 24px 24px; background: #fff">
      <div class="title-text">提供报价</div>
      <a-spin :spinning="form.loading">
        <ul>
          <li class="flex quote-item" v-for="(inquiry, index) in form.data.inquiryGoods" :key="inquiry.id">
            <table class="ant-table myTable">
              <thead class="ant-table-thead" v-if="index === 0">
                <tr>
                  <th class="font-weight h-[22px]" style="width: 100px">配件名称</th>
                  <th class="font-weight h-[22px]" style="width: 100px">数量</th>
                  <th v-if="form.data.required.code === 'Y'" class="font-weight h-[22px]" style="width: 200px">
                    <span class="relative require-dot">配件编码</span>
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr>
                  <td>
                    <div class="w-100px">
                      <a-tooltip v-if="inquiry.name.length > 7" :title="inquiry.name">
                        <div class="w-100px truncate">{{ inquiry.name }}</div>
                      </a-tooltip>
                      <span v-else>{{ inquiry.name }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="w-100px truncate">{{ inquiry.count }}</div>
                  </td>
                  <td v-if="form.data.required.code === 'Y'">
                    <div class="w-200px truncate">
                      <a-input
                        v-model:value="inquiry.oem"
                        :maxlength="20"
                        placeholder="请输入"
                        @change="form.oem.change(inquiry.oem, inquiry)"
                      ></a-input>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <a-table
              class="myTableTwo"
              :pagination="false"
              :showHeader="index === 0"
              :data-source="inquiry.quotationGoods"
              :columns="columns"
              row-key="id"
              style="flex: 1"
            >
              <template #price="{ text, record }">
                <div class="flex items-center">
                  <span>￥</span>
                  <span class="w-[150px] mx-[8px]">
                    <span :class="{ 'switch-block__text': !record.id, 'color-disabled': !Boolean(text) }">{{
                      record.id ? text : record.isDirry ? text : '请输入报价'
                    }}</span>
                    <a-input
                      v-if="!record.id"
                      class="switch-block__input"
                      v-model:value="record.price"
                      @change="form.price.change(record)"
                      placeholder="请输入报价"
                    ></a-input>
                  </span>
                  <a-tooltip v-if="record.isDirry && Number(record.price) === 0" placement="top" color="#fff">
                    <template #title>
                      <span style="color: #333">请确认您输入了正确的报价</span>
                    </template>
                    <ExclamationCircleOutlined style="color: #ffd100; background: #fff" />
                  </a-tooltip>
                </div>
              </template>
              <template #warrantyMonth="{ record }">
                <span class="switch-block">
                  <span :class="{ 'switch-block__text': !record.id }">{{
                    record.warrantyMonth ? `${record.warrantyMonth} 个月 ` : ' 无质保 '
                  }}</span>
                  <a-input-number
                    v-if="!record.id"
                    class="switch-block__input"
                    :precision="0"
                    v-model:value="record.warrantyMonth"
                    :min="0"
                    :max="36"
                  />
                </span>
              </template>
              <template #reserveDays="{ record }">
                <span class="switch-block">
                  <a-input-number
                    v-if="!record.id"
                    class="switch-block__input"
                    :precision="0"
                    v-model:value="record.reserveDays"
                    :min="0"
                    :max="99"
                    @change="form.reserveDays.change(record)"
                  />
                  <span :class="{ 'switch-block__text': !record.id }">{{
                    record.reserveDays ? `${record.reserveDays} 天 ` : ' 现货 '
                  }}</span>
                </span>
              </template>
              <template #comment="{ record }">
                <a-input
                  v-if="!record.id"
                  v-model:value="record.comment"
                  :maxlength="20"
                  placeholder="请输入"
                ></a-input>
                <span v-else>{{ record.comment }}</span>
              </template>
              <template #operation="{ record, index }">
                <a
                  type="link"
                  class="operation-model text-primary"
                  @click="form.add.click(inquiry.quotationGoods, record)"
                  >添加</a
                >
                <a-button
                  v-if="record.isDelete"
                  type="link"
                  class="operation-model text-primary"
                  @click="form.delete.click(inquiry.quotationGoods, index)"
                  >删除</a-button
                >
                <a-button></a-button>
              </template>
            </a-table>
          </li>
        </ul>
        <div class="flex justify-end" style="margin-top: 24px" :loading="form.loading">
          <a-button
            type="primary"
            :loading="form.submit.loading"
            @click="form.submit.click(form.data.supplierQuoted.code !== 'Y')"
            >{{ form.data.supplierQuoted.code === 'Y' ? '追加报价' : '发布' }}</a-button
          >
        </div>
      </a-spin>
    </div>
  </view-wrapper>
  <a-modal v-model:visible="imgPreview.visible" :footer="null" @cancel="imgPreview.visible = false">
    <div style="margin-top: 20px">
      <img alt="image" style="width: 100%" :src="imgPreview.imgUrl" />
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAjax, useStore, formatDate, useWebim } from '@vue-mfe/utils'
import { cloneDeep, debounce } from 'lodash-es'
import {
  message,
  Modal as AModal,
  Col as ACol,
  Row as ARow,
  Form as AForm,
  Spin as ASpin,
  Tooltip as ATooltip,
  InputNumber as AInputNumber
} from 'ant-design-vue'

import { PhoneFilled, FileImageOutlined, MessageFilled, ExclamationCircleOutlined } from '@ant-design/icons-vue'

const columns = [
  {
    title: '品质类型',
    width: 100,
    dataIndex: 'qualityType.message'
  },
  {
    title: '报价',
    width: 150,
    dataIndex: 'price',
    slots: { customRender: 'price' }
  },
  {
    title: '质保',
    width: 180,
    slots: { customRender: 'warrantyMonth' }
  },
  {
    title: '订货周期',
    width: 180,
    slots: { customRender: 'reserveDays' }
  },
  {
    title: '备注',
    width: 150,
    slots: { customRender: 'comment' }
  },
  {
    title: '操作',
    width: 180,
    slots: { customRender: 'operation' }
  }
]
const QUALITIES = [
  { code: 'O', message: '原厂件' },
  { code: 'B', message: '品牌件' },
  { code: 'D', message: '拆车件' }
]
const quotationInit = (props: any) =>
  Object.assign(
    {
      id: '',
      qualityType: { code: '', message: '' },
      price: '',
      inventoryExplain: {
        code: 'S',
        message: '现货'
      },
      oem: '',
      comment: '',
      reserveDays: 0,
      warrantyMonth: 6
    },
    props
  )
export default defineComponent(
  {
    name: '',
    // props: {
    //   id: {
    //     type: String,
    //     required: true
    //   }
    // },
    components: {
      AForm,
      ARow,
      ACol,
      ASpin,
      ATooltip,
      AInputNumber,
      AModal,
      ExclamationCircleOutlined,
      PhoneFilled,
      FileImageOutlined,
      MessageFilled
    },
    setup () {
      const webim = useWebim()
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const id = route.params.id
      let { loading: getLoading, run: getQuoteDetail } = useAjax(
        {
          action: 'GET /supplier/quotation/:inquiryId',
          params: id,
          success (res) {
            let data = res[0]
            const inquiryQualityTypes = data.inquiryQualityTypes.sort(
              (star: any, next: any) => {
                const codeOrder = ['O', 'B', 'D']
                return codeOrder.indexOf(star.code) - codeOrder.indexOf(next.code)
              }
            )
            data.inquiryQualityTypes = inquiryQualityTypes
            data.inquiryGoods.forEach(
              (item: any) => {
                item.oem = ''
                if (!item.quotationGoods.length) {
                  for (let type of inquiryQualityTypes) {
                    item.quotationGoods.push(
                      quotationInit(
                        {
                          qualityType: type,
                          isDelete: false,
                          warrantyMonth: store.state.user.supplier?.guaranteePeriod,
                          isDirry: false
                        }
                      )
                    )
                  }
                } else {
                  const exiteTypeCodes = item.quotationGoods.map((inner: any) => inner.qualityType.code)
                  for (let type of inquiryQualityTypes) {
                    if (!exiteTypeCodes.includes(type.code))
                      item.quotationGoods.push(
                        quotationInit(
                          {
                            qualityType: type,
                            isDelete: false,
                            warrantyMonth: store.state.user.supplier?.guaranteePeriod,
                            isDirry: false
                          }
                        )
                      )
                  }
                  item.oem = item.quotationGoods[0].oem
                }
              }
            )
            form.data = Object.assign(form.data, data)
          },
          lazy: true
        }
      )
      const form = reactive(
        {
          loading: getLoading,
          data: {
            chatUser: {
              name: '',
              cellphone: ''
            } as any,
            serialNo: '',
            branchName: '',
            tenantShortName: '',
            commitBy: '',
            addressCellphone: '',
            commitDatetime: '',
            addressDetail: '',
            preparedBy: '',
            enocloudCommitUser: {
              name: '',
              cellphone: ''
            },
            preparedDatetime: '',
            inquiryQualityTypes: [
              {
                message: ''
              }
            ],
            vin: '',
            vehicleBrand: '',
            vehicleDisplacement: '',
            vehicleGroup: '',
            vehiclePlateNo: '',
            vehiclePrefix: '',
            vehicleSubBrand: '',
            vehicleSystem: '',
            vehicleTransmission: '',
            vehicleYear: '',
            invoiceType: {
              message: ''
            },
            supplierQuoted: {
              code: ''
            },
            comment: '',
            required: {
              code: ''
            },
            inquiryImages: [],
            inquiryGoods: [
              {
                id: 12548,
                oem: '',
                name: '',
                count: '',
                quotationGoods: [
                  {
                    id: '',
                    oem: '',
                    price: '',
                    qualityType: {
                      message: ''
                    },
                    reserveDays: '',
                    inventoryExplain: {
                      message: ''
                    },
                    warrantyMonth: ''
                  }
                ]
              }
            ],
            addressArea: {
              code: '',
              name: '',
              parent: {
                name: '',
                parent: {
                  name: ''
                }
              }
            }
          },
          get: getQuoteDetail,
          submit: {
            loading: false,
            click (flag?: boolean) {
              const { inquiryGoods } = form.data
              const priceTips = []
              const oemTips = []
              const newInquiryGoods = cloneDeep(inquiryGoods).map(
                (item: any) => {
                  item.quotationGoods = item.quotationGoods.filter((inner: any) => inner.isDirry)
                  return item
                }
              )
              if (newInquiryGoods.every((item) => !item.quotationGoods.length)) {
                return message.warning('至少需要一个报价商品!')
              }
              newInquiryGoods.forEach(
                (item: any) => {
                  item.quotationGoods.forEach(
                    (inner: any) => {
                      if (Number(inner.price) === 0) {
                        priceTips.push(Object.assign(inner, { name: item.name }))
                      }
                      if (form.data.required.code === 'Y' && !inner.oem) {
                        oemTips.push(Object.assign(inner, { name: item.name }))
                      }
                    }
                  )
                }
              )
              if (oemTips.length) return message.warning('报价配件编码必填')
              const postQuotation = useAjax(
                {
                  action: 'POST /supplier/quotation',
                  params: () => Object.assign(form.data, { inquiryGoods: newInquiryGoods }),
                  lazy: true
                }
              )
              form.submit.loading = postQuotation.loading.value
              if (priceTips.length) {
                AModal.confirm(
                  {
                    iconType: 'warning',
                    title: () => '温馨提示',
                    content: () => `有${priceTips.length}个0元报价，是否继续发布？`,
                    onOk: () =>
                      postQuotation
                        .run()
                        .then(
                          () => {
                            message.success('发布成功')
                            if (flag) {
                              webim.flush()
                            }
                            router.push({ path: '/supplier/supply/quote' })
                          }
                        )
                        .catch(() => {}),
                    onCancel: () => {}
                  }
                )
              } else {
                postQuotation.run().then(
                  () => {
                    message.success('发布成功')
                    if (flag) {
                      webim.flush()
                    }
                    router.push({ path: '/supplier/supply/quote' })
                  }
                )
              }
            }
          },
          oem: {
            change (val: string, item: any) {
              item.quotationGoods.forEach(
                (inner: any) => {
                  if (!inner.id) inner.oem = val
                }
              )
            }
          },
          price: {
            change: debounce(
              (record) => {
                if (!record.price) {
                  record.isDirry = false
                  record.price = ''
                } else {
                  let val = record.price
                  record.isDirry = true
                  val = val
                    .toString()
                    .replace(/[^\d^.]+/g, '')
                    .replace('.', '$#$')
                    .replace(/\./g, '')
                    .replace('$#$', '.')
                  val = Number(val).toFixed(2)
                  if (val > 999999.99) val = 999999.99
                  record.price = val
                }
              },
              800
            )
          },
          reserveDays: {
            change (record: any) {
              if (!record.reserveDays) record.inventoryExplain = { code: 'S', message: '现货' }
              else record.inventoryExplain = { code: 'D', message: '定货' }
            }
          },
          add: {
            click (quotationGoods: any, row: any) {
              quotationGoods.push(
                quotationInit(
                  {
                    qualityType: row.qualityType,
                    isDelete: true,
                    isDirry: false,
                    oem: row.oem,
                    warrantyMonth: store.state.user.supplier?.guaranteePeriod
                  }
                )
              )
            }
          },
          delete: {
            click (quotationGoods: any, index: any) {
              quotationGoods.splice(index, 1)
            }
          },
          preview: {
            click () {
              if (!form.data.inquiryImages.length) return false
              imgPreview.click(form.data.inquiryImages[0])
            }
          }
        }
      )
      const imgPreview = reactive(
        {
          visible: false,
          imgUrl: '',
          click (url: string) {
            imgPreview.imgUrl = url
            imgPreview.visible = true
          }
        }
      )
      form.get()
      const address = computed(
        () => {
          const { addressArea, addressDetail } = form.data
          if (addressArea)
            return addressArea.parent && addressArea.parent.parent
              ? `${addressArea.parent.parent.name}/${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
              : `${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
          return ''
        }
      )
      const vehicleSpec = computed(
        () => {
          const { vehicleBrand, vehicleSubBrand, vehicleSystem, vehicleYear, vin, vehicleDisplacement } = form.data
          return vin
            ? `${vehicleBrand}${
                vehicleSubBrand ? `/${vehicleSubBrand}` : ''
              } ${vehicleSystem} ${vehicleYear} ${vehicleDisplacement}`
            : vehicleBrand
        }
      )
      const qualitytypes = computed(
        () => {
          const { inquiryQualityTypes } = form.data
          // if (inquiryQualityTypes)
          return inquiryQualityTypes
            .map((item) => QUALITIES.find((inner: any) => inner.code === item)?.message)
            .join(',')
        }
      )
      return {
        form,
        columns,
        address,
        imgPreview,
        vehicleSpec,
        qualitytypes,
        formatDate,
        webim
      }
    }
  }
)
</script>

<style scoped>
.title-text {
  font-size: 16px;
  padding-top: 24px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}
.quote-item {
  border: 1px solid #e9e9e9;
}
.myTable {
  min-width: 400px;
}
.myTable .ant-table-tbody > tr > td {
  border: none;
}
.myTableTwo.ant-table-wrapper :deep(.ant-table-tbody > tr > td:first-child) {
  border-left: 1px solid #e9e9e9;
}
/* .switch-block {
  width: 140px;
  margin: 0 8px;
} */
/* .switch-block__text {
  flex: 1;
} */
.switch-block__input {
  display: none;
}
:deep(.ant-table-row-cell-break-word:hover) .switch-block__input {
  display: block;
}
:deep(.ant-table-row-cell-break-word:hover) .switch-block__text {
  display: none;
}
.color-disabled {
  color: #999;
}
.require-dot::before {
  position: absolute;
  left: 100%;
  top: -2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>
